import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  max-width: max-content;
  margin: 24px auto 0;
`

const Wrapper = styled.div`
  max-width: ${(props) => props.theme.containerWidths.medium};
  margin-left: auto;
  margin-right: auto;
  text-align: center;

  .headline--with-line-design {
    margin-bottom: 12px; // override
  }

  .headline--title {
    font-size: 18px;
    line-height: 20px;
    font-family: ${(props) => props.theme.typography.serif};
  }

  h4 {
    display: block;
    margin-top: 16px;
    margin-bottom: 2px;
    font-family: ${props => props.theme.typography.sansSerif};
    text-transform: uppercase;
    font-weight: 500;
  }

  .award-text {
    font-size: 14px;
  }

  .section-title {
    margin-bottom: 0;

    + .headline--title,
    + .text-center {
      margin-top: 12px;

      @media screen and (min-width: ${(props) =>
    props.theme.responsive.large}) {
        margin-top: 16px;
      }
    }

    + .headline--intro {
      font-style: italic;
    }
  }

  .recipients-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(min(150px, 100%), 1fr));
    grid-gap: 12px;
    margin-top: 10px;

    &.no-col-heading {
      margin-top: 16px;
    }

    p {
      font-weight: 500;
      line-height: 1.2;

      + p {
        margin-top: 0px;
      }

      span {
        font-weight: 300;
        font-size: 14px;
        line-height: 1;
      }
    }

    div {
      p {
        line-height: 1.6;

        + p {
          margin-top: 12px;
        }
      }
    }
  }

  @media screen and (min-width: ${(props) => props.theme.responsive.large}) {
    .headline--title {
      font-size: 22px;
      line-height: 24px;
    }
  }
`

const ArtistRecognition = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulAsset(
        filter: { description: { regex: "/.*RankAdvancements.*/" } }
        sort: { fields: description }
      ) {
        edges {
          node {
            id
            title
            file {
              url
            }
          }
        }
      }
    }
  `)

  const contentfulPDFs = data.allContentfulAsset.edges.map(({ node }) => ({
    ...node,
  }))

  return (
    <Wrapper className="wrap">
      <section className="section">
        <h3 className="headline--reunion center-align">Artist Recognition</h3>
        <p>
          <span className="semi-bold-weight">Reunion Qualification Period:</span><br />
          March 1st, 2023 - July 31st, 2024
        </p>
        <p style={{ marginBottom: '30px' }}>
          We can't wait to gather together and celebrate the remarkable hard work and dedication of our talented Artists.
          As we journey through the transition to an affiliate program, we look forward to honoring your achievements and
          milestones- from March 1, 2023, to July 31, 2024-under our current model. Let's follow the Yellow Brick Road to
          an unforgettable celebration of YOU!
        </p>
        <h4>Hall of Fame Display</h4>
        <p className="award-text">
          Highest rank ever achieved
        </p>
        <h4>New High Ranks</h4>
        <p className="award-text">
          New high ranks March 1st, 2023 through July 31st, 2024, for Artist III+
        </p>
        <h4>Artist of the year (per rank)</h4>
        <p className="award-text">
          Overall success between sales, enrollers, and team growth
        </p>
        <h4>Top Sales Club</h4>
        <p className="award-text">
          Artists who achieved sales (SV) of $250K-$999,999K from March 1st, 2023 - July 31st, 2024.<br />
          <span style={{ fontStyle: 'italic' }}>*New qualifiers</span>
        </p>
        <h4>Seint’s Club</h4>
        <p className="award-text">
          Total lifetime sales (SV) from March 1st, 2023 - July 31st, 2024 for those that have sold $1M+.<br />
          <span style={{ fontStyle: 'italic' }}>*New qualifiers</span>
        </p>
        <h4>Top Sales</h4>
        <p className="award-text">
          Top 10 Artists in each rank who have the highest sales (CV) from March 1st, 2023 - July 31st, 2024. Artist II and above
        </p>
        <h4>Top Enrollers</h4>
        <p className="award-text">
          Top Artists in each rank who have the most enrollers from March 1st, 2023 - July 31st, 2024.  Artist II and above
        </p>
        <h4>Top Team Leaders</h4>
        <p className="award-text">
          Top 10 Artists in each rank who have the most active directs from March 1st, 2023 - July 31st, 2024. Artist II and above
        </p>
        <h4>Consistent Rank</h4>
        <p className="award-text">
          Artists who hit their same high rank 14 out of 17 months from March 1st, 2023 - July 31st, 2024 for Artist III and above.
        </p>
        <h4>Specialty Awards</h4>
        <p className="award-text">
          In addition to our achievement-based recognition awards, we want to celebrate some of our unsung heroes
          nominated by their fellow Artists. Please take the time to nominate Artists you think exemplify these
          attributes and deserve a little recognition on stage at Over the Rainbow. You have until July 31st, 2024 to
          consider these awards and submit your nominations here:{' '}
          <a
            href="https://docs.google.com/forms/d/e/1FAIpQLSdkV3D2ZLFqlk9ggLh1oFnSSSPShyGHgMpvZcmeT1VsFf0Z2g/viewform"
            target="_blank"
            rel="noopener noreferrer"
          >Nomination form</a>
        </p>
        <h5 style={{ fontWeight: 'bold' }}>Spirit Award</h5>
        <p className="award-text">
          Optimistic and lively, this Artist brings a scoop of humor and a heap of inspiration to the table! She
          has a lightness about her that draws others to her, and her positivity and ability to turn even the most
          mundane tasks into fun is the icing to our cake.
        </p>
        <h5 style={{ fontWeight: 'bold' }}>Adventure Award</h5>
        <p className="award-text">
          This Artist isn’t afraid to take on a challenge! Her successes are hard won through daily hard work and
          sheer determination. She’s infused with a magnetic kind of energy that draws you in and a relentless
          drive to think outside of the box, turning brilliant ideas into fresh solutions.
        </p>
        <h5 style={{ fontWeight: 'bold' }}>Mentor Award</h5>
        <p className="award-text">
          This Artist is warm and approachable, with a strong desire and motivation to guide others- and that’s
          only the beginning! She’s truly invested in the success of those around her, knowing that lifting others
          lifts her higher than she could ever be on her own. Her commitment to her team is legendary and it shows!
        </p>
        <h5 style={{ fontWeight: 'bold' }}>Over the Rainbow Award</h5>
        <p className="award-text">
          Bluebirds symbolize hope, love, positivity, and renewal. In the same way, this Artist embodies an attitude of
          new beginnings and second chances, and she shares that attitude with everyone around her. She recognizes the
          strength within herself to fly high over the rainbow, and isn’t afraid to have big dreams. She has confidence
          in herself, in her team, and in what they can achieve together.
        </p>
        <h5 style={{ fontWeight: 'bold' }}>Multitasker Award</h5>
        <p className="award-text">
          No matter what sour thing comes her way, this Artist can turn it into something
          sweet! She is running long after others have stopped- her hustle is legendary. She’s a fountain of
          productivity with limitless potential, and her ability to find a balance and harmony between all of the
          demands on her time and attention inspires us all.
        </p>
        <h5 style={{ fontWeight: 'bold' }}>Humanitarian Award</h5>
        <p className="award-text">
          This Artist not only embraces a culture of abundance; she’s committed to sharing hers with others.
          She’s richly involved in causes she is passionate about and is working hard to make this world a better
          place, one beautiful soul at a time.
        </p>
        <h5 style={{ fontWeight: 'bold' }}>Social Media Award</h5>
        <p className="award-text">
          A consistent and beautiful vibe surrounds this Artist's Instagram feed, creating an inspiring mosaic of
          purposeful squares that draw us in and invite us to linger there. Her refreshing perspective and
          inspiring words turn even the most common materials into a masterpiece.
        </p>
        <h5 style={{ fontWeight: 'bold' }}>Makeover Award</h5>
        <p className="award-text">
          Everything she touches turns to gold! This Artist sees the beauty in faces and knows how to accentuate
          features with consistently beautiful makeovers that boast Seint’s signature glow. By helping others see
          their beauty, she’s in the business of changing lives.
        </p>
        <h4>3D Foundation Artists</h4>
        <p className="award-text">
          Foster, Adoptive, and Licensed Artists
        </p>
      </section>
    </Wrapper>
  )
}

export default ArtistRecognition
